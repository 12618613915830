<script>
export default {
  name: "FormInput",
  inject: ["validation", "name"],
  props: {
    placeholder: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: "text"
    },
    value: {
      type: [String, Boolean],
      default: ""
    }
  },
  data() {
    return {
      innerValue: this.value
    };
  },
  watch: {
    value(newVal) {
      this.innerValue = newVal;
    },
    innerValue(newVal) {
      this.$emit("input", newVal);
    }
}
};
</script>
<template>
  <input
    v-if="type !== 'checkbox'"
    :id="name"
    v-model.trim="innerValue"
    :name="name"
    :type="type"
    :placeholder="placeholder"
    :class="{ danger: validation.$invalid && validation.$dirty }"
    @blur="validation.$touch()"
  />
  <input
    v-else
    :id="name"
    v-model="innerValue"
    :name="name"
    :type="type"
    :class="{ danger: validation.$invalid && validation.$dirty }"
    @change="validation.$touch()"
  />
</template>

<style lang="scss" scoped>
@import "../../scss/mixins.scss";

input {
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 1rem;
  padding: 0.75rem 1rem;
  width: 100%;
  &.danger {
    border-color: #ff3860;
  }
  &.success {
    border-color: green;
  }
  @include from($tablet) {
    // font-size: 1rem;
  }
}
</style>
