<script>
import { required } from "vuelidate/lib/validators";
import { validateErrors } from "./validationErrors.js";
import { EMPTY_FIELD, NOT_EXIST, EXPIRED, DEFAULT_FALLBACK, getErrorMessage, fallbackErrorMessage } from "@/backendException.js";
import { LoginByToken } from "@/models/actions/operations.gql";
import FormField from "./FormField.vue";
import FormInput from "./FormInput.vue";

export default {
  name: "VerificationForm",
  components: { FormField, FormInput },
  props: {
    instruction: {
      type: String,
      required: true
    },
    submitButtonText: {
      type: String,
      required: true
    },
    email: {
      type: String,
      required: true
    },
    redirectLocation: {
      type: String,
      required: true
    },
    successSnackbarMessage: {
      type: String,
      default: "An email has been sent to your email. Please check your inbox."
    },
    formErrorToastMessage: {
      type: String,
      default: "Please enter the verification code you were sent."
    }
  },
  data() {
    return {
      token: "",
      successSnackbar: null,
      formErrorToast: null,
      validationReasonMap: new Map([
        [EMPTY_FIELD, "Required field must be filled out."],
        [NOT_EXIST, "Entered code does not exist."],
        [EXPIRED, "Code is expired. Please request a new one."],
        [DEFAULT_FALLBACK, fallbackErrorMessage]
      ])
    };
  },
  mounted() {
    this.successSnackbar = this.$buefy.snackbar.open({
      queue: false,
      message: this.successSnackbarMessage,
      position: "is-top",
      type: "is-success",
      actionText: null,
      indefinite: true
    });
  },
  beforeDestroy() {
    this.clearAlerts();
  },
  validations: {
    token: { required }
  },
  methods: {
    validateErrors,
    uppercaseToken() {
      this.token = this.token.toUpperCase();
    },
    async loginByToken() {
      try {
        const response = await this.$apollo.query({
          query: LoginByToken,
          variables: {
            token: this.token
          }
        });
        //this.$log.info("response of loginByToken", response);
        const { accessToken, user } = response.data.result.value || {};
        const { errors } = response.data.result;
        if (errors?.length > 0) {
          this.formErrorToast = this.$buefy.toast.open({
            message: getErrorMessage(errors, this.validationReasonMap),
            type: "is-danger"
          });
        } else {
          this.$auth.refreshAuthUser({ accessToken: accessToken, user: user });
          this.$router.push(this.redirectLocation);
        }
      } catch (error) {
        this.formErrorToast = this.$buefy.toast.open({
          message: fallbackErrorMessage,
          type: "is-danger"
        });
      }
    },
    submitForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.formErrorToast = this.$buefy.toast.open({
          message: this.formErrorToastMessage,
          type: "is-danger"
        });
      } else {
        this.loginByToken();
      }
    },
    clearAlerts() {
      if (this.successSnackbar) {
        this.successSnackbar.close();
      }
      if (this.formErrorToast) {
        this.formErrorToast.close();
      }
    }
  }
};
</script>

<template>
  <form @submit.prevent="submitForm">
    <p>{{ instruction }}</p>
    <FormField name="token" label="Enter Code" :validation="$v.token" :hide-label="true" :error-message="validateErrors($v.token, 'Code')">
      <FormInput v-model.trim="$v.token.$model" placeholder="Enter Code" type="text" @input="uppercaseToken" />
    </FormField>
    <button type="submit">{{ submitButtonText }}</button>
  </form>
</template>

<style lang="scss" scoped>
@import "../../scss/mixins.scss";

form {
  display: flex;
  flex-direction: column;
  margin: 2.5rem;
  gap: 1.25rem;
  button {
    background-color: white;
    border: 1px solid $border;
    border-radius: 2px;
    color: $blue;
    font-family: $family-secondary;
    font-size: 1rem;
    font-weight: 500;
    height: auto;
    text-align: center;
    padding: 0.75rem;
    white-space: nowrap;
    cursor: pointer;
    &:hover {
      background-color: darken(white, 5%);
    }
  }
  p {
    text-align: left;
    color: $grey700;
    font-size: 0.875rem;
  }
}
</style>
