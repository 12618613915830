import { render, staticRenderFns } from "./VerificationForm.vue?vue&type=template&id=777a6fe4&scoped=true&"
import script from "./VerificationForm.vue?vue&type=script&lang=js&"
export * from "./VerificationForm.vue?vue&type=script&lang=js&"
import style0 from "./VerificationForm.vue?vue&type=style&index=0&id=777a6fe4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "777a6fe4",
  null
  
)

export default component.exports