<script>
export default {
  name: "FormField",
  provide() {
    return {
      validation: this.validation,
      name: this.name
    };
  },
  props: {
    label: {
      type: String,
      default: ""
    },
    name: {
      type: String,
      default: ""
    },
    validation: {
      type: Object,
      default: () => ({})
    },
    errorMessage: {
      type: String,
      default: ""
    },
    successMessage: {
      type: String,
      default: ""
    },
    hideLabel: {
      type: Boolean,
      default: false
    }
  },
  emits: ["update:validationState"],
  watch: {
    validation: {
      handler(newVal) {
        if (newVal.$dirty) {
          this.$emit("update:validationState", !newVal.$invalid);
        }
      },
      deep: true
    }
  }
};
</script>

<template>
  <div class="field">
    <label :class="{ 'hide-label': hideLabel }" :for="name">{{ label }}</label>
    <!-- input slot -->
    <slot></slot>
    <p v-if="validation.$dirty" class="danger">
      {{ errorMessage }}
    </p>
    <p class="success">
      {{ successMessage }}
    </p>
  </div>
</template>

<style lang="scss" scoped>
@import "../../scss/mixins.scss";
.field {
  position: relative;
  label {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0.5rem;
  }
  p {
    position: absolute;
    left: 0;
    font-size: 0.75rem;
    margin-top: 0.2rem;
    line-height: 1.1em;
    &.danger {
      color: #ff3860;
    }
    &.success {
      color: green;
    }
  }
}
.hide-label {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
</style>
