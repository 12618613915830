<script>
import SignUpForm from "./SignUpForm.vue";
import VerificationForm from "./VerificationForm.vue";

export default {
  name: "SignUp",
  components: { SignUpForm, VerificationForm },
  data() {
    return {
      submittedEmail: null,
      userRegistered: false
    };
  },
  methods: {
    handleEmailSubmission(email) {
      this.submittedEmail = email;
      this.userRegistered = true;
    },
    getLoginRoute() {
      const routeData = { name: "login" };
      if (this.$route.query.redirectUrl) {
        routeData.query = { redirectUrl: this.$route.query.redirectUrl };
      }
      return routeData;
    }
  }
};
</script>

<template>
  <section>
    <div class="main-container">
      <div class="form-container">
        <SignUpForm v-if="!userRegistered" @emailSubmitted="handleEmailSubmission" />
        <VerificationForm v-else
          :email="submittedEmail"
          :instruction="'Complete your registration by clicking the verification link we\'ve sent to your email. Or, enter the code from your verification email below.'" 
          :submit-button-text="'Verify Code'" 
          :redirect-location="'complete_account'"
          :success-snackbar-message="`An email has been sent to ${this.submittedEmail} with a link to complete your registration.`"
          :form-error-toast-message="'Please enter the verification code you were sent to complete your registration.'"
          />
        <div class="extra-links">
          <p v-if="!userRegistered">
            Already have an account? <router-link :to="getLoginRoute()">Log In.</router-link>
          </p>
          <p v-else>
            Didn't receive an email?
            <span @click="userRegistered = false">Try Again.</span>
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
@import "../../scss/mixins.scss";
section {
  display: flex;
  justify-content: center;
  width: 100vw;
  .main-container {
    min-height: 75vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1700px;
    margin-top: 1rem;
    gap: 1rem;
    .form-container {
      background-color: white;
      border: 1px solid #dcdee9;
      max-width: 400px;
      .extra-links {
        margin-bottom: 2rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        p {
          color: $grey700;
          font-size: 1rem;
        }
        a {
          font-size: 1rem;
          font-weight: 500;
        }
        span {
          cursor: pointer;
          color: $blue;
        }
      }
    }
    @include from($tablet) {
      margin: 2rem;
      gap: 2rem;
      .form-container {
        width: 400px;
      }
    }
  }
}
</style>
