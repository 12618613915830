import { helpers } from "vuelidate/lib/validators";

export const containsLowercase = value => /[a-z]/.test(value);
export const containsUppercase = value => /[A-Z]/.test(value);
export const containsNumber = value => /\d/.test(value);
export const containsNoUppercase = value => !/[A-Z]/.test(value);
export const containsValidCharacters = helpers.regex("valid_characters", /^[a-zA-Z0-9.\-_()]+$/);

export const validateErrors = (validationObject, fieldName) => {
  const { $dirty, required, email, minLength, maxLength, containsLowercase, containsUppercase, containsNumber, containsValidCharacters, containsNoUppercase, alphaNum, $params } = validationObject;
  if (!$dirty) return "";
  if (required === false) return `${fieldName} is required.`;
  if (minLength === false) return `${fieldName} must be at least ${$params.minLength.min} characters long.`;
  if (maxLength === false) return `${fieldName} must be less than ${$params.maxLength.max} characters long.`;
  if (containsLowercase === false) return `${fieldName} must contain at least one lowercase letter.`;
  if (containsUppercase === false) return `${fieldName} must contain at least one uppercase letter.`;
  if (containsNumber === false) return `${fieldName} must contain at least one number.`;
  if (containsValidCharacters === false) return `${fieldName} must contain only letters, numbers, and the . _ - characters.`;
  if (containsNoUppercase === false) return `${fieldName} cannot contain uppercase letters.`;
  if (alphaNum === false) return `${fieldName} can only contain letters and digits.`;
  if (email === false) return "Please enter a valid email.";
  return "";
};
