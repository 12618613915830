<script>
import { RegisterUser } from "@/models/actions/operations.gql";
import { required, email, maxLength } from "vuelidate/lib/validators";
import { validateErrors } from "./validationErrors.js";
import { EMPTY_FIELD, INVALID_FORMAT, ALREADY_EXISTS, NOT_EXIST, INSERT_FAILED, CANCELLED, TOO_EARLY, TOO_LATE, NOT_SUPPORTED, DEFAULT_FALLBACK, getErrorMessage, fallbackErrorMessage } from "@/backendException.js";
import FormField from "./FormField.vue";
import FormInput from "./FormInput.vue";

export default {
  name: "SignUpForm",
  components: { FormField, FormInput },
  emits: ["emailSubmitted"],
  data() {
    return {
      redirectUrl: null,
      firstName: "",
      lastName: "",
      email: "",
      formErrorToast: null,
      validationReasonMap_registerUser: new Map([
        [EMPTY_FIELD, "All fields must be filled out."],
        [INVALID_FORMAT, "Email format is invalid."],
        [ALREADY_EXISTS, "An account with this email already exists. Log in or reset your password."],
        [DEFAULT_FALLBACK, fallbackErrorMessage]
      ]),
      executionReasonMap_registerUser: new Map([
        [INSERT_FAILED, "Unable to save registration. Please try again or contact support if the issue persists."],
        [DEFAULT_FALLBACK, fallbackErrorMessage]
      ])
    };
  },
  beforeDestroy() {
    this.clearAlerts();
  },
  validations: {
    firstName: {
      required,
      maxLength: maxLength(40)
    },
    lastName: {
      required,
      maxLength: maxLength(40)
    },
    email: {
      required,
      email
    }
  },
  methods: {
    validateErrors,
    async registerUser() {
      let userId = null;
      try {
        const response = await this.$apollo.mutate({
          mutation: RegisterUser,
          variables: {
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email
          }
        });
        const errors = response.data.result.errors;
        if (errors?.length > 0) {
          this.formErrorToast = this.$buefy.toast.open({
            message: getErrorMessage(errors, this.validationReasonMap_registerUser, this.executionReasonMap_registerUser),
            type: "is-danger"
          });
          return null;
        } else {
          userId = response.data.result.id;
        }
      } catch (error) {
        this.formErrorToast = this.$buefy.toast.open({
          message: fallbackErrorMessage,
          type: "is-danger"
        });
        return null;
      }
      return userId;
    },
    async submitForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.formErrorToast = this.$buefy.toast.open({
          message: "Please fill out form to create account.",
          type: "is-danger"
        });
        return;
      }
      const userId = await this.registerUser();
      if (userId) {
        let eventId = null;
        this.$emit("emailSubmitted", this.email);
      }
    },
    clearAlerts() {
      if (this.formErrorToast) {
        this.formErrorToast.close();
      }
    }
  }
};
</script>

<template>
  <form @submit.prevent="submitForm">
    <FormField name="firstName" label="First Name" :validation="$v.firstName" :hide-label="true" :error-message="validateErrors($v.firstName, 'First name')">
      <FormInput v-model.trim="$v.firstName.$model" placeholder="First Name" type="text" />
    </FormField>
    <FormField name="lastName" label="Last Name" :validation="$v.lastName" :hide-label="true" :error-message="validateErrors($v.lastName, 'Last name')">
      <FormInput v-model.trim="$v.lastName.$model" placeholder="Last Name" type="text" />
    </FormField>
    <FormField name="email" label="Email:" :validation="$v.email" :hide-label="true" :error-message="validateErrors($v.email, 'Email')">
      <FormInput v-model.trim="$v.email.$model" placeholder="Email" type="email" />
    </FormField>
    <button type="submit">Create Account</button>
  </form>
</template>

<style lang="scss" scoped>
@import "../../scss/mixins.scss";

form {
  display: flex;
  flex-direction: column;
  margin: 2.5rem;
  gap: 1.25rem;
  button {
    background-color: white;
    border: 1px solid $border;
    border-radius: 2px;
    color: $blue;
    font-family: $family-secondary;
    font-size: 1rem;
    font-weight: 500;
    height: auto;
    text-align: center;
    padding: 0.75rem;
    white-space: nowrap;
    cursor: pointer;
    &:hover {
      background-color: darken(white, 5%);
    }
  }
}
</style>
